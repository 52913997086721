<template>
  <v-row justify="start">
    <v-dialog v-model="dialogEmpty" persistent max-width="400px">
      <v-card class="radius-card">
        <v-card-text class="pa-0">
          <v-container>
            <section class="text-center">
              <v-icon size="70">mdi-tooltip-image-outline</v-icon>
            </section>
            <section class="text-center">
              <h1>Cover is empty!</h1>
              <h3>Want to next?</h3>
              <p class="grey--text ma-0">Cover will be set default</p>
            </section>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="mr-2"
            outlined
            rounded
            @click="$emit('close')"
            >Cancel</v-btn
          >
          <v-btn color="blue" outlined rounded @click="$emit('next')"
            >Next</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "addCampus",

  props: ["dialogEmpty"]
};
</script>

<style></style>
